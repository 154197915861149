import { Layout } from 'components'
import ForgotPasswordView from 'domains/AffiliateDashboard/ForgotPassword'
import React from 'react'

const ForgotPassword = () => (
  <>
    <Layout>
      <ForgotPasswordView />
    </Layout>
  </>
)

export default ForgotPassword
