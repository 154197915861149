import { Button } from 'components'
import { darkBlueGrey, lightGold } from 'components/baseColor'
import styled from 'styled-components'
import { IS_FRAME_MOBILE } from 'utils'

export const Wrapper = styled.div`
  padding: 2rem 35% 8rem;

  > h4 {
    font-size: 1.25rem;
    color: ${darkBlueGrey};
  }

  > p {
    font-size: 0.875rem;
    color: ${darkBlueGrey};
    letter-spacing: 0.25px;
  }

  > a {
    color: ${darkBlueGrey};
    font-size: 0.875rem;
    margin-top: 2rem;
    display: block;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;

    > svg {
      margin-right: 0.75rem;
    }

    > svg path {
      fill: ${darkBlueGrey};
    }
  }

  ${IS_FRAME_MOBILE} {
    margin-left: 0;
    padding: 0 1rem 2.5rem;

    > h4 {
      margin: 0.5rem 0;
      font-size: 1.25rem;
      font-weight: 500;
    }

    > p {
      line-height: 1.57;
      margin-top: 0.25rem;
    }

     span {
      font-size: 0.875rem;
    }
     
     input {
      font-size: 0.875rem;
    }
  }
`

export const ResetButton = styled(Button)`
  background-color: ${lightGold};
  border-color: ${lightGold};
  width: 100%;
  padding: 0.5rem 0;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 0.875rem;
  margin-top: 1rem;
` 

export const Img = styled.img`
  display: block;
  margin: auto;
  width: 60%;

  ${IS_FRAME_MOBILE} {
    width: 70%;
  }
`