import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link , navigate } from 'gatsby'
import { ArrowLeftLong } from '@qlue/icon-kit'
import { InputText } from 'components'
import {
  Wrapper,
  ResetButton ,
  Img
} from './styles'
import QlueUnityDashboardIcon from 'media/images/affiliate-dashboard/qlueunity-dashboard-icon.png'
import APIConfig, { AffiliateAuthURL } from 'commons/API'
import { isBrowser } from 'utils'
import { useToasts } from 'react-toast-notifications'
import { generateElementID, FORGOT_PASSWORD_ID_PREFIX } from 'utils/generateElementID'


const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Your email is not valid')
    .required('Required')
})

const ForgotPasswordView = () => {
  const { addToast } = useToasts()

  const handleSubmitForm = async (values, e) => {
    e.preventDefault()
    const HOST = isBrowser ? window.location.host : ''
    const PROTOCOL = isBrowser ? window.location.protocol : ''

    const activation_link = `${PROTOCOL}//${HOST}/affiliate/set-new-password/`

    await APIConfig.post(AffiliateAuthURL.ForgotPasswordAffiliate, {
      email: values.email,
      activation_link
    }).then((res) => {
      addToast('An email reset password has been succesfully sent! Kindly check your email.')
      navigate('/affiliate-dashboard/login')
    }).catch((err) => {
      console.log(err)
    })
  }

  const { 
    handleSubmit, 
    formState: {
      errors,
      isSubmitting
    }, 
    control
  } = useForm({
    resolver      : yupResolver(ValidationSchema),
    mode          : 'onTouched',
    defaultValues : {
      email: ''
    }
  })

  return(
    <Wrapper>
      <Img 
        src={ QlueUnityDashboardIcon } 
        alt='QlueUnity'
      />
      <h4>
        Do you forget password?
      </h4>
      <p>
        Don’t worry! Please type your email address you are using in Qlue, and we will help you to reset it.
      </p>
    
      <form onSubmit={ handleSubmit(handleSubmitForm) }>
        <Controller 
          render={ ({ field, ref }) => (
            <InputText 
              title={ 'Email' }
              placeholder='Enter your email here'
              error={ errors?.email?.message ? errors.email.message : '' }
              ref={ field.ref } 
              { ...field }
              id={ generateElementID(FORGOT_PASSWORD_ID_PREFIX, 'email') }
            />
          ) }
          control={ control }
          name='email'
        /> 

        <ResetButton  
          type='submit' 
          disabled={ isSubmitting }
          id={ generateElementID(FORGOT_PASSWORD_ID_PREFIX, 'btnressetpassword') }
        >
          RESET PASSWORD
        </ResetButton >
      </form>

      <Link
        id={ generateElementID(FORGOT_PASSWORD_ID_PREFIX, 'btnbacktologin') } 
        to='/affiliate-dashboard/login'
      >
        <ArrowLeftLong width='1.1rem' />
        Back to Log in
      </Link>
    </Wrapper>
  )}

export default ForgotPasswordView
